<template>
    <div class="banner">
        <div class="swiper-container" ref="mySwiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in homeData.banners" :key="index">
                    <img :src="item.imgUrl" alt="">
                    <!-- <div class="content">
                        <div class="title">{{item.title}}</div>
                        <div class="subTilte">{{item.subTilte}}</div>
                    </div> -->
                </div>
                
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>

            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>

        <div class="introduce wapper">
            <div class="top">
                <h4 class="title">{{homeData.title}}</h4>
            </div>

            <div class="bottom">
                <p class="introduceContent">{{homeData.content}}</p>
                <div class="nav">
                    <ul>
                        <li v-for="(title, index) in titles" :key="index">
                            <div class="title">{{title}}</div>
                            <div class="navContent">{{contents[index]}}</div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import Swiper from 'swiper/swiper-bundle.min'
export default {
    name: 'Home',

    data() {
        return {
            homeData: {},

            effect: 'fade',
        };
    },

    computed: {
        titles() {
            if(!this.homeData.navsList) return
            return this.homeData.navsList.titles
        },
        contents() {
            if(!this.homeData.navsList) return
            return this.homeData.navsList.contents
        },
    },

    mounted() {
        this.getHome()
        this.init()
    },

    methods: {
        async getHome() {
            let {code, data} = await this.$http.get('/home')
            if(code === 200) {
                this.homeData = data
            }
        },

        init() {
            new Swiper (this.$refs.mySwiper, {
                direction: 'horizontal', // 垂直切换选项
                loop: true, // 循环模式选项
                autoplay: true,
                effect: this.effect,
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                },

                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                // 如果需要滚动条
                scrollbar: {
                    el: '.swiper-scrollbar',
                },

                /* on: {
                    slideChangeTransitionStart: function(){
                        let effects = ['fade',"flip",'cards',"creative","cube","coverflow"]
                        let index = Math.round(Math.random() * (effects.length - 0) + 0)
                        this.effect = effects[index  - 1]
                        console.log(this.effect)
                    },
                }, */
            })
        },
    },

    watch: {
        homeData: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if(newValue.length < 0 ) {
                    return
                }
                this.$nextTick(() => {
                    this.init()
                });
            }
        },

        /* effect: {
            immediate: true,
            deep: true,
            handler(newValue) {
                console.log('newValue: ', newValue);
                console.log('asdfasf')
                this.init()
            }
        } */
    }

};
</script>

<style lang="less" scoped>
    .top {
        padding: 40px 35px ;
        border-bottom: 1px solid #ccc;
        .title {
            font-size: 22px;
        }
    }
    .bottom {
        width: 85%;
        margin: 10px auto 30px;
        .introduceContent {
            text-indent: 2em;
            margin: 45px auto;
            font-size: 18px;
        }
        .nav {
            margin: 85px auto;
            ul {
                // margin: 50px 0 70px 0px;
                display: flex;
                justify-content: space-between;
                li {
                    width: 150px;
                    height: 150px;
                    border: 2px solid #40a9ff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    .title {
                        color: #40a9ff;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    
    
    .banner {
        position: relative;
        // min-height: calc(430px/100vw*100%);
    }
    .banner .content {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 50px;
        margin: auto;
        text-align: center;
        color: #8fbc8f;
    }
    .banner .content .title {
        font-size: 32px;
    }
    .banner img {
        height: 660px;
        width: 100%;
    }
    .swiper-button-prev,
    .swiper-button-next {
        color: #fff;
        width: 30px;
        height: 60px;
        background: rgba(0,0,0,.3);
        font-size: 12px;
        line-height: 60px;
        text-align: center;
    }
    /deep/ .swiper-pagination-bullet-active {
        background: #fff;
    }
</style>